<template>
  <div :class="baseClass + '-mobile-all'">
    <div :class="baseClass + '-mobile-all-title'">All Games</div>
    <div :class="baseClass + '-mobile-all-content'">
      <Game
        v-for="item of games"
        :item="item"
        :key="item.id"
        type="mobile-item"
      />
    </div>
    <div :class="baseClass + '-mobile-all-action'">
      <img @click="more" class="img" src="~@/assets/images/phone-more.png" />
    </div>
  </div>
</template>

<script>
import Game from "@/components/GameItem.vue";
export default {
  components: { Game },
  data() {
    return {
      games: [],
    };
  },
  mounted() {
    this.more();
  },
  methods: {
    more() {
      this.$store.dispatch("randomData", {
        num: 99,
        callback: (games) => {
          console.log(games[0].id);
          this.games = games;
          this.$store.dispatch("resetScrollTop");
        },
      });
    },
  },
};
</script>

<style lang="scss">
.#{$baseClass}-mobile-all {
  background: #006cf3;
  box-shadow: 0px 4px 12px 0px rgba(0, 108, 243, 0.8);
  border-radius: 8px;
  position: relative;
  &-action {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1px;
    margin-bottom: 78px;
    position: absolute;
    width: 100%;
    .img {
      width: 134px;
      height: 78px;
    }
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
  }
  &-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background: #fedc32;
    box-shadow: inset 0px 4px 4px 0px #fff3b6, inset 0px -4px 4px 0px #feaa55;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    text-shadow: 0px 2px 0px #feaa56;
  }
}
</style>
