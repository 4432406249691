<template>
  <div :class="baseClass + '-mobile-index-page'">
    <div :class="baseClass + '-mobile-index-page-top'">
      <div :class="baseClass + '-mobile-index-page-top-title'">New Release</div>
      <div :class="baseClass + '-mobile-index-page-top-content'">
        <div :class="baseClass + '-mobile-index-page-top-content-box'">
          <Game
            v-for="item of $store.state.mobileNew"
            :item="item"
            :key="item.id"
            type="mobile-new"
          />
        </div>
      </div>
    </div>
    <div :class="baseClass + '-mobile-index-page-ads'"></div>
    <AllGames />
    <Tips />
  </div>
</template>
<script>
import Game from "@/components/GameItem.vue";
import AllGames from "@/components/MobileAllGames.vue";
import Tips from "@/components/Tips.vue";
export default {
  components: { Game, AllGames, Tips },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss">
.#{$baseClass}-mobile-index-page {
  padding: 12px;

  &-ads {
    width: 100%;
    height: 280px;
    // background: #9b9b9b;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  &-top {
    // width: 351px;
    width: 100%;
    background: #fedc32;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    position: relative;
    &::after,
    &::before {
      width: 8px;
      height: 8px;
      content: "";
      position: absolute;
      right: calc(50% + 6px);
      top: 0px;
    }
    &::before {
      background: #5eaaff;
    }
    &::after {
      border-top-right-radius: 8px;
      background: #fedc32;
    }
    &-content {
      height: 248px;
      position: relative;
      margin-top: 10px;
      &::after,
      &::before {
        width: 8px;
        height: 8px;
        content: "";
        position: absolute;
        right: 0;
        top: -10px;
      }
      &::before {
        background: #5eaaff;
      }
      &::after {
        border-top-right-radius: 8px;
        background: #fedc32;
      }
      &-box {
        width: 100%;
        height: 100%;
        background: #006cf3;
        box-shadow: 0px 4px 12px 0px rgba(0, 108, 243, 0.8);
        border-radius: 8px;
        position: relative;
        z-index: 3;
        display: flex;
        flex-wrap: wrap;
      }
    }
    &-title {
      width: 100%;
      height: 40px;
      font-size: 20px;
      padding-left: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      text-shadow: 0px 1px 0px #feaa56;
      display: flex;
      align-items: center;
      position: relative;
      padding-top: 10px;
      &::before,
      &::after {
        content: "";
        position: absolute;
        right: 0;
        height: 100%;
        width: 50%;
        top: 0;
        background: #5eaaff;
      }
      &::after {
        transform: rotateZ(0deg) skew(22deg, 0deg);
        border-bottom-left-radius: 10px;
      }
      &::before {
        width: 20%;
      }
    }
  }
}
</style>
